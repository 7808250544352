import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const StyledCard = styled.div`
  .card-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0.1em 0.1em 0.5em rgba(60, 60, 60, 65%);
    @media screen and (min-width: 540px) {
      width: 80%;
    }
    .card-image {
      @media screen and (min-width: 540px) {
        height: 304px;
      }
      @media (min-width: 1180px) {
        transform: translateX(-20px);
      }
    }

    background-color: var(--darkgrey);
    @media (min-width: 1180px) {
      width: 500px;
      flex-direction: row;
      background-color: transparent;
      box-shadow: -0.7em 0.3em 0.5em rgb(60 60 60 / 65%);
    }
    .project-text {
      width: 264px;

      height: 100%;
      color: var(--white);
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media screen and (min-width: 540px) {
        min-height: 340px;
      }
      .project-name {
        font-size: 20px;
        font-family: Poppins;
        margin: 0;
      }
      .project-description {
        font-size: 1.5rem;
      }
      @media (min-width: 1180px) {
        position: absolute;
        height: 110%;
        top: -5%;
        right: -5px;
        background: var(--darkgrey);
        box-shadow: 0.1em 0.1em 0.5em rgba(60, 60, 60, 65%);
      }
    }
    .btn {
      display: inline-block;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }
`;

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    text = text.substr(0, maxLength);
    text = `${text.substr(0, Math.min(text.length, text.lastIndexOf(' ')))}...`;
  }
  return text;
}

const cardVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
      delay: 0.2,
    },
  },
};

const MotionCard = motion(StyledCard);

export default function CompletedProjectCard({ project }) {
  const image = getImage(project.headerImage.asset.gatsbyImageData);
  const projectDescription = truncateText(project.description, 150);
  return (
    <MotionCard variants={cardVariants}>
      <div className="card-box">
        <GatsbyImage className="card-image" image={image} alt={project.name} />
        <div className="project-text">
          <p className="project-name">{project.name}</p>
          <p className="project-description">{projectDescription}</p>
          <Link className="btn" to={`/projects/${project.slug.current}`}>
            View Project
          </Link>
        </div>
      </div>
    </MotionCard>
  );
}
