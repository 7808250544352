/* eslint-disable prettier/prettier */
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import MapboxGL from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const StyledContent = styled.section`
  background: var(--charcoal);
  h3 {
    padding: 3rem 0;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    color: var(--white);
  }
  .map-container {
    height: 580px;
    .map {
      height: 580px;
      width: 100%;
    }
    @media (min-width: 1024px) {
      height: 678px;
    }
    display: grid;
    .background-image,
    .map {
      width: 100%;
      height: auto;
      grid-area: 1/1;
      object-position: 0 0;
    }
    button {
      padding: 1px 6px;
      box-shadow: none;
      color: var(--black);
    }
  }
`;

export default function MapSection() {
  const mapRef = useRef();

  const data = useStaticQuery(graphql`
    query MapSectionQuery {
      allProject {
        nodes {
          name
          id
          lat
          lng
          slug {
            current
          }
          description
        }
      }
    }
  `);
  const projects = data.allProject.nodes;

  MapboxGL.accessToken =
    'pk.eyJ1IjoiY2FuZHJlczQxIiwiYSI6ImNrcXNnamJ6NDJxZXoyb28xeWl1andpYWoifQ.WmJA5O1HE-fgfuDgOaPkig';
  useEffect(() => {
    const map = new MapboxGL.Map({
      container: mapRef.current,
      style: 'mapbox://styles/candres41/ckqsgk0fi14hp17rrb1ic8cr4',
      center: [-121.89502716064453, 37.33577030970146],
      zoom: 7,
    });

    // create a dataset
    // update the dataset to include the projects name, description, icon and slug
    // add the layers
    for (const project of projects) {
      project.html = `<strong>${project.name}</strong><p>${project.description}</p><p><a href="/projects/${project.slug.current}" title="${project.name} Project Page" className="${project.slug.current}-link">View Project<a/></p> `;
    }
    const features = projects.map((project) => ({
      type: 'Feature',
      properties: {
        description: project.html,
        icon: 'town-hall-15',
      },
      geometry: {
        type: 'Point',
        coordinates: [project.lng, project.lat],
      },
    }));
    const geojson = {
      type: 'FeatureCollection',
      features,
    };
    map.on('load', function () {
      map.addSource('places', {
        // This GeoJSON contains features that include an "icon"
        // property. The value of the "icon" property corresponds
        // to an image in the Mapbox Streets style's sprite.
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features,
        },
      });
      map.addLayer({
        id: 'points',
        type: 'symbol',
        source: 'places',
        layout: {
          'icon-image': '{icon}',
          'icon-allow-overlap': true,
        },
      });
    });

    // When a click event occurs on a feature in the places layer, open a popup at the
    // location of the feature, with description HTML from its properties.

    map.on('click', 'points', (e) => {
      // Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();
      const { description } = e.features[0].properties;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new MapboxGL.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(map);
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.on('mouseenter', 'places', () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    map.on('mouseleave', 'places', () => {
      map.getCanvas().style.cursor = '';
    });
  }, []);

  return (
    <StyledContent>
      <h3>View our Featured Projects by Location</h3>
      <div className="map-container">
        <div className="map" ref={mapRef} />
      </div>
    </StyledContent>
  );
}
