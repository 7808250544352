import React from 'react';
import styled from 'styled-components';
import CompletedProjectCard from './CompletedProjectCard';
import { motion } from 'framer-motion';

const StyledCompletedProjectSection = styled.section`
  text-align: center;
  padding-top: 75px;
  padding-bottom: 75px;
  h2 {
    padding-bottom: 40px;
    @media (min-width: 768px) {
      padding-bottom: 60px;
    }
  }
  .project-grid {
    display: grid;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 1fr;
    padding-left: 40px;
    padding-right: 40px;
    max-width: 1280px;
    row-gap: 40px;
    @media (min-width: 768px) {
      row-gap: 80px;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      align-items: center;
    }
  }
`;
const itemVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
      delay: 0.1,
    },
  },
};

const MotionCompletedProjectSection = motion(StyledCompletedProjectSection);

export default function CompletedProjectSection({ CompletedProjects }) {
  const projects = CompletedProjects.map((project) => (
    <CompletedProjectCard project={project} key={project.name} />
  ));
  return (
    <MotionCompletedProjectSection
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
    >
      <motion.h2 variants={itemVariants}>Completed Projects</motion.h2>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, margin: '100px' }}
        className="project-grid"
      >
        {projects}
      </motion.div>
    </MotionCompletedProjectSection>
  );
}
