import { graphql } from 'gatsby';
import React from 'react';
import Hero from '../components/Hero';
import Applications from '../components/Applications';
import CompletedProjectSection from '../components/CompletedProjectSection';
import MapSection from '../components/MapSection';
import SEO from '../components/SEO';
import styled from 'styled-components';
import Gallery from '@browniebroke/gatsby-image-gallery';
import { AnimatePresence, motion } from 'framer-motion';

const StyledContent = styled.section`
  width: 95%;
  @media (min-width: 768px) {
    width: 90%;
  }
  max-width: 1280px;
  margin: 0 auto;
  .gallery-section {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 75px;
    .gatsby-image-wrapper {
      filter: none;
      transition: filter 0.3s ease-in-out;
      &:hover {
        transition: filter 0.3s ease-in-out;
        filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
      }
    }
  }
`;

const GalleryGridWrapper = styled.div`
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
  width: min-content;
  max-width: 768px;
  width: 100%;
  > div {
    justify-content: ${(props) => (props.images > 3 ? 'flex-start' : 'center')};
    margin-right: 0;
    margin-left: 0;
  }
`;

const MotionGalleryGridWrapper = motion(GalleryGridWrapper);

const itemVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
      delay: 0.1,
    },
  },
};
const galleryVariants = {
  offscreen: {
    opacity: 0,
  },
  onscreen: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

export default function ProjectsPage({ data, location }) {
  const { pageData } = data;
  const images = pageData.galleryImages.map((image) => image.asset);
  return (
    <>
      <SEO title={'All Projects'} />
      <AnimatePresence>
        <Hero
          bg={pageData}
          heading={pageData.name}
          subText={pageData.description}
          bgAlt={pageData.headerImage.altText}
          location={location}
        />
      </AnimatePresence>
      <CompletedProjectSection CompletedProjects={pageData.projects} />
      <StyledContent>
        <Applications applications={pageData.applications} />
        <motion.div
          variants={galleryVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.25 }}
          className="gallery-section"
        >
          <motion.h2 variants={itemVariants}>Gallery</motion.h2>
          <MotionGalleryGridWrapper
            variants={itemVariants}
            images={images.length}
          >
            <Gallery images={images} />
          </MotionGalleryGridWrapper>
        </motion.div>
      </StyledContent>
      <MapSection />
    </>
  );
}

export const query = graphql`
  query {
    pageData: sanityProjectsPage(
      id: { eq: "-3166227a-e4aa-555c-b4e9-c3c3030d1914" }
    ) {
      projects {
        name
        id
        description
        slug {
          current
        }
        headerImage {
          asset {
            gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
          }
        }
      }
      headerImage {
        altText
        asset {
          gatsbyImageData
        }
      }
      backgroundImage {
        altText
        asset {
          gatsbyImageData
        }
      }
      name
      description
      applications {
        icon {
          asset {
            gatsbyImageData(height: 72)
          }
        }
        name
        id
      }
      galleryImages {
        asset {
          thumb: gatsbyImageData(width: 195, height: 195)
          full: gatsbyImageData(layout: FULL_WIDTH)
        }
        altText
        imageCaption
      }
    }
  }
`;
