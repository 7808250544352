import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import IconCard from './IconCard';

const ApplicationSection = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-top: 75px;
  text-align: center;
  width: calc(100vw - 120px);
  max-width: 750px;
  h2 {
    padding-bottom: 40px;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`;

const MotionIconCard = motion(IconCard);
const MotionApplicationSection = motion(ApplicationSection);

const itemVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
      delay: 0.1,
    },
  },
};

export default function Applications({ applications, name }) {
  const applicationCards = applications.map((application) => (
    <MotionIconCard data={application} key={application.id} />
  ));

  return (
    <MotionApplicationSection
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
    >
      <motion.h2 variants={itemVariants}>{name} Applications</motion.h2>
      <div className="grid">{applicationCards}</div>
    </MotionApplicationSection>
  );
}
